import { NgModule } from '@angular/core';
import { LocksTableComponent } from './locks-table/locks-table.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SlideOutComponent } from './slide-out/slide-out.component';
import { CommonModule } from '@angular/common';
import { BatteryLevelComponent } from './battery-level/battery-level.component';
import { LockInfoComponent } from './lock-info/lock-info.component';
import { AgmCoreModule } from '@agm/core';
import { MomentModule } from 'ngx-moment';
import { LastUnlockComponent } from './last-unlock/last-unlock.component';
import { PermissionsTableComponent } from './permissions-table/permissions-table.component';
import { TimezoneAwareDateTimeComponent } from './timezone-aware-date-time/timezone-aware-date-time.component';
import { PermissionInfoComponent } from './permission-info/permission-info.component';
import { LockInfoShortComponent } from './lock-info-short/lock-info-short.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';

import { LockActivityTableComponent } from './lock-activity-table/lock-activity-table.component';
import { ActivityActionComponent } from './activity-action/activity-action.component';
import { ActivityInfoComponent } from './activity-info/activity-info.component';
import { DeviceInfoComponent } from './device-info/device-info.component';
import { UniqueCodesTableComponent } from './unique-codes-table/unique-codes-table.component';
import { EmbeddedMapComponent } from './embedded-map/embedded-map.component';
import { LockActivationComponent } from './lock-activation/lock-activation.component';
import { LockStatItemComponent } from './lock-stat-item/lock-stat-item.component';
import { LockStatsBarComponent } from './lock-stats-bar/lock-stats-bar.component';
import {
  NbCardModule,
  NbPopoverModule,
  NbButtonModule,
  NbDatepickerModule,
  NbIconModule,
  NbStepperModule,
  NbRadioModule,
  NbSelectModule,
  NbCheckboxModule,
  NbInputModule,
  NbAlertModule,
  NbTooltipModule,
  NbContextMenuModule,
  NbSpinnerModule,
  NbToggleModule,
  NbAutocompleteModule,
  NbTagModule,
  NbListModule,
  NbTabsetModule,
  NbButtonGroupModule,
  NbTimepickerModule,
  NbFormFieldModule,
} from '@nebular/theme';
import { NewItemButtonComponent } from './new-item-button/new-item-button.component';
import { NewUniqueCodeComponent } from './new-unique-code/new-unique-code.component';
import { DateRangeSelectorComponent } from './date-range-selector/date-range-selector.component';
import { ExportDataButtonComponent } from './export-data-button/export-data-button.component';
import { LockStatsPieChartComponent } from './lock-stats-pie-chart/lock-stats-pie-chart.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { SystemStatsBarComponent } from './system-stats-bar/system-stats-bar.component';
import { LocksShareComponent } from './locks-share/locks-share.component';
import { DlDateTimeDateModule, DlDateTimePickerModule } from 'angular-bootstrap-datetimepicker';
import { LockNameComponent } from './lock-name/lock-name.component';
import { LockSelectorComponent } from './lock-selector/lock-selector.component';
import { LockGroupEditComponent } from './lock-group-edit/lock-group-edit.component';
import { LockGroupManageLocksComponent } from './lock-group-manage-locks/lock-group-manage-locks.component';
import { BasicDetailsComponent } from './basic-details/basic-details.component';
import { NewLockGroupComponent } from './new-lock-group/new-lock-group.component';
import { NewTeamComponent } from './new-team/new-team.component';
import { TeamEditComponent } from './team-edit/team-edit.component';
import { LockEditComponent } from './lock-edit/lock-edit.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ActivityTrendsChartComponent } from './activity-trends-chart/activity-trends-chart.component';
import { ChartModule } from 'angular2-chartjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RemoveLockFromGroupConfirmationComponent } from './remove-lock-from-group-confirmation/remove-lock-from-group-confirmation.component';
import { RemoveMemberFromTeamConfirmationComponent } from './remove-member-from-team-confirmation/remove-member-from-team-confirmation.component';
import { RemoveGroupConfirmationComponent } from './remove-group-confirmation/remove-group-confirmation.component';
import { RemoveTeamConfirmationComponent } from './remove-team-confirmation/remove-team-confirmation.component';
// tslint:disable-next-line: max-line-length
import { BaseConfirmationWithPermissionsComponent } from './base-confirmation-with-permissions/base-confirmation-with-permissions.component';
import { UserDetailsItemComponent } from './user-details-item/user-details-item.component';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { DownloadConfirmationComponent } from './download-confirmation/download-confirmation.component';
import { NbSecurityModule } from '@nebular/security';
import { LockOwnerComponent } from './lock-owner/lock-owner.component';
import { LockBilledComponent } from './lock-billed/lock-billed.component';
import { LockStatusComponent } from './lock-status/lock-status.component';
import { HubShortInfoComponent } from './hub-short-info/hub-short-info.component';
import { HubLockKeyViewComponent } from './hub-lock-key-view/hub-lock-key-view.component';
import { HubLockIconViewComponent } from './hub-lock-icon-view/hub-lock-icon-view.component';
import { FakeArrayPipe } from '../pipes/fake-array.pipe';
import { UserIntegrationComponent } from './user-integration/user-integration.component';
import { HubCardComponent } from './hub-card/hub-card.component';
import { HubTableComponent } from './hub-table/hub-table.component';
import { HubViewLocksButtonComponent } from './hub-view-locks-button/hub-view-locks-button.component';
import { HubDetailWindowComponent } from './hub-detail-window/hub-detail-window.component';
import { HubLockListViewComponent } from './hub-lock-list-view/hub-lock-list-view.component';
import { HubActionButtonComponent } from './hub-action-button/hub-action-button.component';
import { RemoveLockFromHubButtonComponent } from './remove-lock-from-hub-button/remove-lock-from-hub-button.component';
import { ActivityAwareLockInfoShortComponent } from './activity-aware-lock-info-short/activity-aware-lock-info-short.component';
import { HubSlideoutComponent } from './hub-slideout/hub-slideout.component';
import { TransactionalTelemetryComponent } from './transactional-telemetry/transactional-telemetry.component';
import { TransactionalTelemetryItemComponent } from './transactional-telemetry-item/transactional-telemetry-item.component';
import { LockSortPipe } from '../pipes/lock-sort.pipe';
import { WithLoadingPipe } from '../pipes/with-loading.pipe';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PermissionHelpComponent } from './permission-help/permission-help.component';
import { UserAliasComponent } from './user-alias/user-alias.component';
import { ResourceFilterComponent } from './resource-filter/resource-filter.component';

@NgModule({
  imports: [
    Ng2SmartTableModule,
    CommonModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDv2VxpaVPJAoq6HcMJtyLUSqkzpTbcXMk',
      libraries: ['places'],
    }),
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatFormFieldModule,
    NbCardModule,
    NbPopoverModule,
    NbButtonModule,
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NgxEchartsModule,
    NbIconModule,
    NbStepperModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
    DlDateTimeDateModule,
    DlDateTimePickerModule,
    NbCheckboxModule,
    NbInputModule,
    NbAlertModule,
    ChartModule,
    NbContextMenuModule,
    NbSpinnerModule,
    NbSecurityModule,
    NbToggleModule,
    NbAutocompleteModule,
    NbTagModule,
    NbListModule,
    NbTabsetModule,
    NbButtonGroupModule,
    NbFormFieldModule,
  ],
  declarations: [
    LocksTableComponent,
    SlideOutComponent,
    BatteryLevelComponent,
    LockInfoComponent,
    LastUnlockComponent,
    PermissionsTableComponent,
    TimezoneAwareDateTimeComponent,
    PermissionInfoComponent,
    LockInfoShortComponent,
    LockActivityTableComponent,
    ActivityActionComponent,
    ActivityInfoComponent,
    DeviceInfoComponent,
    UniqueCodesTableComponent,
    EmbeddedMapComponent,
    LockActivationComponent,
    LockStatItemComponent,
    LockStatsBarComponent,
    NewItemButtonComponent,
    NewUniqueCodeComponent,
    DateRangeSelectorComponent,
    ExportDataButtonComponent,
    LockStatsPieChartComponent,
    SystemStatsBarComponent,
    LocksShareComponent,
    LockNameComponent,
    LockSelectorComponent,
    LockGroupEditComponent,
    LockGroupManageLocksComponent,
    BasicDetailsComponent,
    NewLockGroupComponent,
    NewTeamComponent,
    TeamEditComponent,
    LockEditComponent,
    ConfirmDialogComponent,
    ActivityTrendsChartComponent,
    BaseConfirmationWithPermissionsComponent,
    RemoveLockFromGroupConfirmationComponent,
    RemoveMemberFromTeamConfirmationComponent,
    RemoveGroupConfirmationComponent,
    RemoveTeamConfirmationComponent,
    UserDetailsItemComponent,
    DropdownButtonComponent,
    DownloadConfirmationComponent,
    LockOwnerComponent,
    LockBilledComponent,
    LockStatusComponent,
    HubShortInfoComponent,
    HubLockKeyViewComponent,
    HubLockIconViewComponent,
    FakeArrayPipe,
    UserIntegrationComponent,
    HubCardComponent,
    HubTableComponent,
    HubViewLocksButtonComponent,
    HubDetailWindowComponent,
    HubLockListViewComponent,
    HubActionButtonComponent,
    RemoveLockFromHubButtonComponent,
    ActivityAwareLockInfoShortComponent,
    HubSlideoutComponent,
    TransactionalTelemetryComponent,
    TransactionalTelemetryItemComponent,
    LockSortPipe,
    WithLoadingPipe,
    ChangePasswordComponent,
    PermissionHelpComponent,
    UserAliasComponent,
    ResourceFilterComponent,
  ],
  exports: [
    LocksTableComponent,
    PermissionsTableComponent,
    LockActivityTableComponent,
    UniqueCodesTableComponent,
    LockActivationComponent,
    LockStatsBarComponent,
    NewItemButtonComponent,
    NewUniqueCodeComponent,
    DateRangeSelectorComponent,
    ExportDataButtonComponent,
    LockStatsPieChartComponent,
    SystemStatsBarComponent,
    LocksShareComponent,
    LockInfoShortComponent,
    LockGroupEditComponent,
    LockGroupManageLocksComponent,
    NewLockGroupComponent,
    NewTeamComponent,
    TeamEditComponent,
    LockEditComponent,
    ConfirmDialogComponent,
    ActivityTrendsChartComponent,
    BaseConfirmationWithPermissionsComponent,
    RemoveLockFromGroupConfirmationComponent,
    RemoveMemberFromTeamConfirmationComponent,
    RemoveGroupConfirmationComponent,
    RemoveTeamConfirmationComponent,
    UserDetailsItemComponent,
    DropdownButtonComponent,
    HubCardComponent,
    HubTableComponent,
    HubViewLocksButtonComponent,
    TimezoneAwareDateTimeComponent,
    BatteryLevelComponent,
    SlideOutComponent,
    ActivityInfoComponent,
    LockSortPipe,
    WithLoadingPipe,
    UserAliasComponent,
    DeviceInfoComponent,
    ResourceFilterComponent,
    BasicDetailsComponent,
    LockSelectorComponent,
    LockInfoComponent,
  ],
})
export class LockvueSharedComponentsModule {}
