import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';
import { FusionAuthStrategy } from './strategies/fusion-auth.strategy';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    AuthModule.forRoot({
      config: {
        authority: `${environment.faServerUrl}${environment.tenantId}`,
        redirectUrl: `${window.location.origin}/auth/callback`,
        postLoginRoute: '/dashboard',
        postLogoutRedirectUri: `${window.location.origin}`,
        clientId: `${environment.faClientId}`,
        scope: 'openid profile offline_access', // 'openid profile offline_access ' + your scopes
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        logLevel: LogLevel.Debug,
        renewTimeBeforeTokenExpiresInSeconds: 30,
        triggerAuthorizationResultEvent: true,
        secureRoutes: [environment.apiServerUrl],
      },
    }),
  ],
  exports: [AuthModule],
  providers: [FusionAuthStrategy],
})
export class AuthConfigModule {}
